body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: grey;
}

button,
label,
span {
  text-transform: lowercase !important;
}

button span {
  color:gray;
}

/* for the Bargraph element */
.axis {
  fill:"none";
  stroke:white;
  opacity: 1;
  stroke-width:1;
}

.axis .tick {
  fill:"none";
  stroke:gray;
  opacity:1;
  stroke-width:0.5;
}

.majorGrid .tick {
  stroke: lightgrey;
  opacity: 0.7 !important;
  stroke-width:1;
}
.majorGrid path {
    stroke-width: 0;
}

.minorGrid .tick {
  stroke: rgb(66, 63, 63);
  opacity: 0.7 !important;
  stroke-width:1;
}

.minorGrid path {
  stroke-width: 0;
}

.advancedline{
  fill: transparent;
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-opacity: 1;
}


.advancedSvg{
  background-color:#595959;
}

.advancedBorder{
  border: 1px solid lightgray;
}

.advancedSectionHeader{
  border: 1px solid lightgray;
  background-color: #f2f2f4;
  border-radius: 4px;
}

.domain{
  fill:none !important;
}

.advancedCircle{
  fill: #F44336;
  stroke: white;
  stroke-width: 1;
}

svg text{
  fill: gray;
  stroke: none;
  font-size: 10px;
}

div.tooltip {	
  position: absolute;			
  text-align: center;			
  width: 160px;					
  height: 28px;					
  padding: 2px;				
  font: 12px sans-serif;		
  background: lightsteelblue;	
  border: 0px;		
  border-radius: 2px;			
  pointer-events: none;			
}

/* for the Spinner element */
@-webkit-keyframes uil-default-anim {
  0% {
      opacity: 1
  }
  100% {
      opacity: 0
  }
}

.uil-default-css>div:nth-of-type(1) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -.5s;
  animation-delay: -.5s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(2) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -.41666666666667s;
  animation-delay: -.41666666666667s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(3) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -.33333333333333s;
  animation-delay: -.33333333333333s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(4) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -.25s;
  animation-delay: -.25s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(5) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -.16666666666667s;
  animation-delay: -.16666666666667s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(6) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -.083333333333333s;
  animation-delay: -.083333333333333s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(7) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(8) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: .083333333333333s;
  animation-delay: .083333333333333s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(9) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: .16666666666667s;
  animation-delay: .16666666666667s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(10) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: .25s;
  animation-delay: .25s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(11) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: .33333333333333s;
  animation-delay: .33333333333333s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.uil-default-css>div:nth-of-type(12) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: .41666666666667s;
  animation-delay: .41666666666667s
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px
}

.faqQuestion, .faqAnswer {
  text-indent: 1em;
}